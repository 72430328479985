import AMapLoader from '@amap/amap-jsapi-loader'
export const loadMixin = {
  data() {
    return {}
  },
  mounted() {
    this.loadMap()
  },
  methods: {
    loadMap() {
      let that = this
      AMapLoader.load({
        key: this.$constants.KEY,
        plugins: [
          'AMap.Geocoder',
          'AMap.GeoJSON',
          'AMap.MouseTool',
          'AMap.ToolBar',
          'AMap.Scale',
          'AMap.PlaceSearch',
          'AMap.AutoComplete',
          'AMap.PolyEditor'
        ],
        version: '2.0',
        Loca: {
          version: '2.0.0'
        }
      })
        .then(AMap => {
          this.$store.commit('gis/pulginLoadGisChange', true)
          this.$eventBus.$emit('initMapGis', '')
        })
        .catch(e => {
          console.log(e)
        })
    }
  }
}
